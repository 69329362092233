import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const Album = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "uploads" } }, limit: 9) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 100
                formats: [AUTO, WEBP, AVIF]
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  `)

  return (
    <div id='album'>
      <h1 className='display-4 mb-5 text-center'>Recent Photos</h1>
      <div className='row justify-content-center'>
        {data.allFile.edges.map(({ node }) => (
          <div key={node.id} className='col-md-6  col-xl-4 text-center'>
            <GatsbyImage
              image={node.childImageSharp.gatsbyImageData}
              className='img-thumbnail shadow album-item m-4'
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Album
