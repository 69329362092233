import React from 'react'
import Layout from '../components/layout'
import Album from '../components/album'
import Seo from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql, Link } from 'gatsby'

const Index = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <>
      <Seo pageTitle={'Home'} />
      <Layout>
        <div style={{ display: 'grid' }} className='shadow'>
          <StaticImage
            style={{
              gridArea: '1/1',
              height: 'calc(100vh - 5rem)',
            }}
            layout='fullWidth'
            alt=''
            src={'../images/bird.jpg'}
            formats={['auto', 'webp', 'avif']}
            placeholder={'blurred'}
          />
          <div
            style={{
              gridArea: '1/1',
              position: 'relative',
              placeItems: 'center',
              display: 'grid',
              backgroundColor: 'rgba(97, 43, 5, .8)',
            }}
          >
            <div className='row justify-content-center'>
              <div className='col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2 align-middle '>
                <StaticImage
                  src={'../images/icon.png'}
                  alt={'Hum Sath Sewa Leicester Logo'}
                  placeholder='blurred'
                  Layout={'fixed'}
                  formats={['auto', 'webp', 'avif']}
                  width={500}
                  quality={100}
                ></StaticImage>
              </div>
              <div className='col-lg-6 p-3 align-self-center text-light text-center'>
                <h2>
                  Providing aid to Local, National, and International charities
                  since 2015, including LOROS, Rainbows, Braunstone Life
                  Community Centre, Woodgate Community Centre, Action Homeless,
                  Toys on the Table, Iskcon (Mumbai) and more.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div id='blog-list' className='container'>
          <div className='row justify-content-center text-center'>
            <div className='col-lg-8 mt-5'>
              <h1 className='display-4 mb-5'>Recent Posts</h1>
            </div>
          </div>

          {posts.map((post) => {
            return (
              <div key={post.id} className='row justify-content-center'>
                <div className='col-lg-8 mb-3'>
                  <Link to={post.fields.slug}>
                    <h3>{post.frontmatter.title}</h3>
                  </Link>
                  <p className='small text-muted'>{post.frontmatter.date}</p>
                  <p>{post.excerpt}</p>
                  <Link to={post.fields.slug}>read more</Link>
                  <hr />
                </div>
              </div>
            )
          })}

          <Album />
        </div>
      </Layout>
    </>
  )
}

export default Index

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      nodes {
        id
        excerpt(pruneLength: 240)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "dddd DD MMMM YYYY")
          title
        }
        timeToRead
      }
    }
  }
`
